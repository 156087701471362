// eslint-disable-next-line max-classes-per-file
import { logging } from '@inwink/logging';
import type {
    IEventRequestManager, ITenantRequestManager, IRequestManager, IRequestOptions, ICommunityRequestManager
} from '../../apiaccessprovider.definition';
import { combinePath } from '../../../helpers';

const logger = logging.getLogger("API");

let _defaultTimeout: number = null;
export function setDefaultTimeout(timeout: number) {
    _defaultTimeout = timeout;
}

let _defaultFetchOptions: any = {};
export function setDefaultFetchOptions(options: any) {
    _defaultFetchOptions = options;
}

interface IRequestInfo {
    path: string;
    method: string;
    data?: any;
    options?: any;
}

if (!Date.now) {
    Date.now = () => new Date().getTime();
}

export class RequestManager implements IRequestManager {
    constructor(protected baseUrl : string, protected onunauthenticated: () => void) {
    }

    protected _send(request: IRequestInfo): Promise<any> {
        let defaultFetchOptions = null;
        let url = combinePath(this.baseUrl, request.path);

        if (url.indexOf("https://") === 0) {
            defaultFetchOptions = _defaultFetchOptions && _defaultFetchOptions.https;
        } else {
            defaultFetchOptions = _defaultFetchOptions && _defaultFetchOptions.http;
        }
        const headers = new Headers();
        if (request.options && request.options.headers) {
            const keys = Object.keys(request.options.headers);
            keys.forEach((k) => headers.append(k, request.options.headers[k]));
        }

        const fetchOptions = Object.assign({
            method: request.method,
            headers: headers,
            credentials: "include"
        }, defaultFetchOptions);

        if (request.method === "POST" || request.method === "DELETE") {
            fetchOptions.body = request.data;
        }

        if (url.indexOf("http://") !== 0 && url.indexOf('https://') !== 0 && url.indexOf('/') !== 0) {
            url = '/' + url;
        }

        return new Promise((resolveSend, rejectSend) => {
            let timedOut = false;
            let timeoutRef = null;
            const timeout = (request.options && request.options.timeout) || _defaultTimeout;
            if (timeout > 0) {
                timeoutRef = setTimeout(() => {
                    timedOut = true;
                    timeoutRef = null;
                    rejectSend(new Error("request timeout (" + timeout + "ms) " + url));
                }, timeout);
            }

            return fetch(url, fetchOptions).then((response) => {
                if (timedOut) {
                    return null;
                }

                if (timeoutRef) {
                    clearTimeout(timeoutRef);
                }

                // iOS n'a pas d'objet status, cf. : https://developer.mozilla.org/en-US/docs/Web/API/Response
                const httpStatus = (response && response.status)
                    || (response && (response as any).result && (response as any).result.status);

                if (httpStatus === 401) {
                    if (!request.options.disableAuthenticationBubbling) {
                        this.onunauthenticated();
                    }
                    return Promise.reject(response);
                }

                if (httpStatus >= 400) {
                    return Promise.reject(response);
                }

                return response;
            }, (err) => {
                if (timedOut) {
                    return null;
                }
                if (timeoutRef) {
                    clearTimeout(timeoutRef);
                }

                // if (err && err.code === 17) {
                //     this.onunauthorized();
                // }
                logger.error("unhandled fetch error", err);
                return Promise.reject(err);
            }).then((res) => {
                if (!timedOut) {
                    resolveSend(res);
                }
            }, (err) => {
                if (!timedOut) {
                    rejectSend(err);
                }
            });
        });
    }

    protected _processJsonResponse<T>(response: any): T {
        if (response.status === 200) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.text().then((reponseText) => {
                    return JSON.parse(reponseText.replace(/(\u2028|\u2029|\u008D)/g, ""));
                });
            }
        }
        return response;
    }

    _timestamp() {
        return Date.now();
    }

    protected _requestPayload(
        method: string,
        path: string,
        data?: any,
        options?: IRequestOptions
    ): IRequestInfo {
        const payload: IRequestInfo = {
            path,
            method,
            options,
            data
        } as any;
        // if (method === "GET" && IS_IE) {
        //     payload.path += `${path.indexOf("?") === -1 ? '?' : '&'}timestamp=${this._timestamp()}`;
        // }
        return payload;
    }

    public get<T>(url: string, options?: IRequestOptions): Promise<T> {
        return this._send(this._requestPayload("GET", url, undefined, options));
    }

    public getJson<T>(url: string, options?: IRequestOptions): Promise<T> {
        return this.get<T>(url, options).then((res) => this._processJsonResponse(res));
    }

    public post<T>(url: string, data?, options?: IRequestOptions): Promise<T> {
        return this._send(this._requestPayload("POST", url, data, options));
    }

    public postJson<T>(url: string, data?, options?: IRequestOptions): Promise<T> {
        return this.post<T>(url, data, options).then((res) => this._processJsonResponse(res));
    }

    public put<T>(url: string, data?: any, options?: IRequestOptions): Promise<T> {
        return this._send(this._requestPayload("PUT", url, data, options));
    }

    public putJson<T>(url: string, data?: any, options?: IRequestOptions): Promise<T> {
        return this.put<T>(url, data, options).then((res) => this._processJsonResponse(res));
    }

    public delete<T>(url: string, data?: any, options?: IRequestOptions): Promise<T> {
        return this._send(this._requestPayload("DELETE", url, data, options));
    }

    public deleteJson<T>(url: string, data?: any, options?: IRequestOptions): Promise<T> {
        return this.delete<T>(url, data, options).then((res) => this._processJsonResponse(res));
    }
}

export class EventRequestManager extends RequestManager implements IEventRequestManager {
    constructor(public eventId: string, baseUrl : string, protected onunauthenticated: () => void) {
        super(baseUrl, onunauthenticated);
    }
    
    public getUrl(path: string) {
        return combinePath(this.baseUrl, path);
    }

    public getEventJson<T>(url: string, options?): Promise<T> {
        return this.getJson<T>(this.eventId + "/" + url, options);
    }

    public postEventJson<T>(url: string, data?, options?): Promise<T> {
        return this.postJson<T>(this.eventId + "/" + url, data, options);
    }

    public deleteEventJson<T>(url: string, data?, options?): Promise<T> {
        return this.deleteJson<T>(this.eventId + "/" + url, data, options);
    }
}

export class CommunityRequestManager extends RequestManager implements ICommunityRequestManager {
    constructor(public communityId: string, baseUrl : string, protected onunauthenticated: () => void) {
        super(baseUrl, onunauthenticated);
    }

    public getUrl(path: string) {
        return combinePath(this.baseUrl, path);
    }
    
    public getCommunityJson<T>(url: string, options?): Promise<T> {
        return this.getJson<T>(this.communityId + "/" + url, options);
    }

    public postCommunityJson<T>(url: string, data?, options?): Promise<T> {
        return this.postJson<T>(this.communityId + "/" + url, data, options);
    }

    public deleteCommunityJson<T>(url: string, data?, options?): Promise<T> {
        return this.deleteJson<T>(this.communityId + "/" + url, data, options);
    }
}

export class TenantRequestManager extends RequestManager implements ITenantRequestManager {
    constructor(public tenantId: string, baseUrl : string, protected onunauthenticated: () => void) {
        super(baseUrl, onunauthenticated);
    }
}
