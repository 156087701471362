/* eslint-disable @typescript-eslint/no-unused-vars */
import { getAbsoluteUrl } from '@inwink/utils/methods/getabsoluteurl';
import type { IEventRequests, ITenantRequests, IApiAccessManager, ICommunityRequests } from '../../apiaccessprovider.definition';
import { triggerUnauthenticated } from '../../apiaccessprovider';
import {
    setDefaultTimeout, TenantRequestManager, EventRequestManager, CommunityRequestManager
} from './requestmanager';
import { combinePath, getAbsoluteUrlFor } from '../../../helpers';

export const localFrontAPIProxyPath = '/api';

const _g = global as any;

function getFrontApiUrl() {
    if (window.location.host.indexOf("localhost:8888") >= 0 || window.location.host.indexOf("localhost:8181") >= 0) {
        return getAbsoluteUrlFor(inwink.config.frontApiUrl);
    }
    return getAbsoluteUrl(localFrontAPIProxyPath); // getAbsoluteUrlFor(inwink.config.frontApiUrl);
}

function getTenantRequests(tenantid: string): Promise<ITenantRequests> {
    const activityApi = getAbsoluteUrlFor(inwink.config.activityApiUrl || getFrontApiUrl());
    const res = {
        apiFront: new TenantRequestManager(tenantid, getFrontApiUrl(), triggerUnauthenticated),
        apiActivity: new TenantRequestManager(tenantid, activityApi, triggerUnauthenticated),
        initPromise: Promise.resolve(),
        userActions: {
            signIn: (options) => {
                return Promise.reject(new Error("not yet implemented"));
            },
            signOut: () => {
                return Promise.reject(new Error("not yet implemented"));
            },
            localUser: () => {
                return Promise.reject(new Error("not yet implemented"));
            },
            refreshToken: () => {
                return Promise.reject(new Error("not yet implemented"));
            },
            // getToken: () => {
            //     return Promise.reject(new Error("not yet implemented"));
            // },
            clearCurrentUser: () => {

            }
        }
    };

    return Promise.resolve(res);
}

function getEventRequests(eventid: string, tenantid: string, languageProvider: () => string): Promise<IEventRequests> {
    const activityApi = getAbsoluteUrlFor(inwink.config.activityApiUrl || getFrontApiUrl());
    const res = {
        eventid: eventid,
        apiv2: null, // new EventRequestManager(eventid, inwink.config.apiUrl, triggerUnauthenticated),
        apiFront: new EventRequestManager(eventid, getFrontApiUrl(), triggerUnauthenticated),
        apiActivity: new EventRequestManager(eventid, activityApi, triggerUnauthenticated),
        initPromise: Promise.resolve(),
        userActions: {
            clearCurrentUser: () => {

            },
            signIn: (options) => {
                const frontAPI = getFrontApiUrl();
                let authTarget = combinePath(frontAPI, "e", eventid, "signin");
                if (tenantid) {
                    authTarget = combinePath(frontAPI, tenantid, "signin");
                }

                let redirectUri = window.location.href;
                if (!window.location.host.startsWith("localhost:")) {
                    redirectUri = window.location.pathname + window.location.hash;
                    
                    if (options.customRedirect) {
                        redirectUri = options.customRedirect.replace(window.location.origin, "");
                    }
                }

                authTarget = authTarget + "?redirectUri=" + encodeURIComponent(redirectUri);
                window.location.href = authTarget;
                return Promise.resolve();
            },
            signOut: () => {
                const frontAPI = getFrontApiUrl();
                let authTarget = tenantid && combinePath(frontAPI, "e", eventid, "signout");
                if (!authTarget) {
                    return Promise.reject(new Error("no tenant id"));
                }

                if (!window.location.host.startsWith("localhost:")) {
                    authTarget = authTarget + "?redirectUri="
                        + encodeURIComponent(window.location.pathname + window.location.hash);
                } else {
                    authTarget = authTarget + "?redirectUri="
                        + encodeURIComponent(window.location.href);
                }

                if (_g.localStorage) {
                    _g.localStorage.clear();
                }

                window.location.href = authTarget;
                return Promise.resolve();
            },
            localUser: () => {
                return import(/* webpackChunkName: "mod-event-me" */ "../../../modules/event/api/person.me").then((mod) => {
                    return mod.getMe(res, {
                        $all: false,
                        id: true
                    }, languageProvider(), null, true).then((person) => {
                        return {
                            scopes: [],
                            profile: {
                                sub: person.id
                            }
                        };
                    }, (err) => {
                        console.warn("no local user", err);
                        // if (err.status && err.status === 401) {
                        //     return null;
                        // }
                        return null;
                    });
                });
            },
            refreshToken: () => {
                return Promise.resolve(null);
                // return Promise.reject(new Error("not yet implemented"));
            },
            // getToken: () => {
            //     return Promise.resolve(null);
            // }
        }
    };

    return Promise.resolve(res);
}

function getCommunityRequests(
    communityid: string, tenantid: string, languageProvider: () => string
): Promise<ICommunityRequests> {
    const activityApi = getAbsoluteUrlFor(inwink.config.activityApiUrl || getFrontApiUrl());
    const res = {
        communityid: communityid,
        apiv2: null, // new EventRequestManager(eventid, inwink.config.apiUrl, triggerUnauthenticated),
        apiFront: new CommunityRequestManager(communityid, getFrontApiUrl(), triggerUnauthenticated),
        apiActivity: new CommunityRequestManager(communityid, activityApi, triggerUnauthenticated),
        initPromise: Promise.resolve(),
        userActions: {
            clearCurrentUser: () => {

            },
            signIn: (options) => {
                const frontAPI = getFrontApiUrl();
                let authTarget = combinePath(frontAPI, "c", communityid, "signin");
                if (tenantid) {
                    authTarget = combinePath(frontAPI, tenantid, "signin");
                }

                let redirectUri = window.location.href;
                if (!window.location.host.startsWith("localhost:")) {
                    redirectUri = window.location.pathname + window.location.hash;
                    
                    if (options.customRedirect) {
                        redirectUri = options.customRedirect.replace(window.location.origin, "");
                    }
                }
                
                authTarget = authTarget + "?redirectUri=" + encodeURIComponent(redirectUri);
                window.location.href = authTarget;
                return Promise.resolve();
            },
            signOut: () => {
                const frontAPI = getFrontApiUrl();
                let authTarget = tenantid && combinePath(frontAPI, "c", communityid, "signout");
                if (!authTarget) {
                    return Promise.reject(new Error("no tenant id"));
                }

                if (!window.location.host.startsWith("localhost:")) {
                    authTarget = authTarget + "?redirectUri="
                        + encodeURIComponent(window.location.pathname + window.location.hash);
                } else {
                    authTarget = authTarget + "?redirectUri="
                        + encodeURIComponent(window.location.href);
                }

                window.location.href = authTarget;
                return Promise.resolve();
            },
            localUser: () => {
                return import(/* webpackChunkName: "mod-community-me" */ "../../../modules/community/api/me").then((mod) => {
                    return mod.getMe(res.apiFront, communityid).then((member) => {
                        return {
                            scopes: [],
                            profile: {
                                sub: member.id
                            }
                        };
                    }, (err) => {
                        console.warn("no local user", err);
                        // if (err.status && err.status === 401) {
                        //     return null;
                        // }
                        return null;
                    });
                });
                // return Promise.reject(new Error("not yet implemented"));
            },
            refreshToken: () => {
                return Promise.resolve(null);
                // return Promise.reject(new Error("not yet implemented"));
            },
            // getToken: () => {
            //     return Promise.resolve(null);
            // }
        }
    };

    return Promise.resolve(res);
}

export function getCookieBasedAccessManager(languageProvider: () => string, defaultTimeout?: number): IApiAccessManager {
    let currentLanguage = null;

    if (defaultTimeout) {
        setDefaultTimeout(defaultTimeout);
    }

    return {
        getTenantRequests(tenantid: string, redirectUrlAlias?: string) {
            const tenantRequest = getTenantRequests(tenantid);
            return Promise.resolve(tenantRequest);
        },

        getEventRequests(eventid: string, tenantid?: string, redirectUrlAlias?: string) {
            const requestmgr = getEventRequests(eventid, tenantid, languageProvider);
            return Promise.resolve(requestmgr);
        },

        getCommunityRequests(communityid: string, tenantid?: string, redirectUrlAlias?: string) {
            const requestmgr = getCommunityRequests(communityid, tenantid, languageProvider);
            return Promise.resolve(requestmgr);
        },

        setCurrentLanguage(lng: string) {
            currentLanguage = lng;
        },

        setExecModes(conf: any) {
        }
    };
}
